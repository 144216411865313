import { initAutoContextNotification } from "../common/auto-context-notification";
import { fetchAutoContextToasterService } from "./services/auto-context-toaster-service";
class AutoContextualization extends lm.Composant {
  constructor(componentName) {
    super(componentName);
    const autoContextualizationSelector = document.querySelector(`[data-selectorid="auto-contextualization"]`);
    if (!autoContextualizationSelector) return;
    const toasterEventDisplay = "isAutoContextToasterToBeDisplayed";
    const isToasterEventDisplay = sessionStorage.getItem(toasterEventDisplay);
    const isAutoContextualized = autoContextualizationSelector.dataset.isautocontextualized === "true";
    const isContextToasterToShow = autoContextualizationSelector.dataset.iscontexttoastertoshow === "true";
    if (isToasterEventDisplay) {
      fetchAutoContextToasterService(toasterRendering => {
        autoContextualizationSelector.innerHTML = toasterRendering;
        sessionStorage.removeItem(toasterEventDisplay);
        const autoContextToaster = document.querySelector(`[data-selectorid="auto-context-toaster"]`);
        initAutoContextNotification(autoContextToaster);
      });
    } else if (isAutoContextualized) {
      if (isContextToasterToShow) {
        sessionStorage.setItem(toasterEventDisplay, "true");
      }
      window.location.reload();
    }
  }
}
lm.DOMReady(() => {
  new AutoContextualization('autocontextualization');
});